export const InvoicePaymentStatuses = Object.freeze({
  DRAFT: 0,
  APPROVED: 1,
  PAID: 2
})

export const InvoicePaymentStatusTexts = Object.fromEntries(
  Object.entries(InvoicePaymentStatuses).map(a => a.reverse())
)

export const InvoiceItemsStatuses = Object.freeze({
  REGULAR: 0,
  DEDUCTION: 1,
  DISCOUNT: 2
})

export const InvoiceItemsStatusTexts = Object.fromEntries(
  Object.entries(InvoiceItemsStatuses).map(a => a.reverse())
)


export const InvoiceTypes = Object.freeze({
  CARD_TRANSACTIONS: 0,
  BANK_REPORT_TRANSACTIONS: 1,
  COMPANY_INVOICE: 2
})

export const CommonStatuses = Object.freeze({
  QUEUED: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3
})

export const ProductStatuses = Object.freeze({
  STATUS_NEW: 0,
  STATUS_PROCESSING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3,
  STATUS_DELETING: 4
})

export const CommonStatusTexts = Object.fromEntries(
  Object.entries(CommonStatuses).map(a => a.reverse())
)

export const ProcurementProjectStatuses = Object.freeze({
  CODE_PENDING: "PEN",
  CODE_SUBMITTED: "SUB",
  CODE_ACTIVE: "ATC",
  CODE_PIN_ARCHIVED: "PAR",
  CODE_DOC_FINALIZE: "DOC",
  CODE_ITT_PENDING: "ITP",
  CODE_ITT_SUBMITTED: "ITS",
  CODE_ITT: "ITT",
  CODE_MARKING_STAGE: "MKR",
  CODE_IAR: "IAR",
  CODE_COM: "COM"
})

export const FATFormParts = Object.freeze({
  PRELIMINARY: 0,
  SECTION_ONE: 1,
  SECTION_TWO: 2,
  SECTION_THREE: 3,
  SECTION_FOUR: 4,
  SECTION_FIVE: 5,
  SECTION_SIX: 6,
})

export const FATNoticeTypes = Object.freeze({
  F01: {
    value: 0,
    parts: [FATFormParts.PRELIMINARY, FATFormParts.SECTION_ONE, FATFormParts.SECTION_TWO, FATFormParts.SECTION_FOUR, FATFormParts.SECTION_SIX]},
  F02: {
    value: 1,
    parts: [FATFormParts.PRELIMINARY, FATFormParts.SECTION_ONE, FATFormParts.SECTION_TWO, FATFormParts.SECTION_THREE, FATFormParts.SECTION_FOUR, FATFormParts.SECTION_SIX]},
  F03: {
    value: 2,
    parts: [FATFormParts.PRELIMINARY, FATFormParts.SECTION_ONE, FATFormParts.SECTION_TWO, FATFormParts.SECTION_THREE, FATFormParts.SECTION_FOUR, FATFormParts.SECTION_FIVE, FATFormParts.SECTION_SIX]}
})

export const BuyerRoleName = "BUYER"

export const MonthyReportCategories = Object.freeze({
  bff: "Banking and Finance",
  fspf: "Financial Services and Payments"
})
export const SupplierRoleName = "SUPPLIER"
export const OriginatorRoleName = "ORIGINATOR"

export const PinNoticeStatuses = Object.freeze({
  PENDING: 0,
  ACTIVE: 1,
  ARCHIVED: 2
})

export const IttStatuses = Object.freeze({
  PENDING: 2,
  ACTIVE: 1,
  ARCHIVED: 0
})

export const QaQuestionTypes = Object.freeze({
  TYPE_BOOLEAN: 1,
  TYPE_TEXT: 2,
  TYPE_FILE: 3,
  TYPE_HAS_SUB_QUESTION: 4,
  TYPE_TABLE: 5
})

export const GuidanceEvaluationAnswerRequirements = Object.freeze({
  ANSWER_REQUIREMENT_ALL: 0,
  ANSWER_REQUIREMENT_ATLEAST_1: 1,
  ANSWER_REQUIREMENT_NO_ANSWER: 2,
})

export const GuidanceEvaluationAnswerType = Object.freeze({
  NEGATIVE_ANSWER: 0,
  POSITIVE_ANSWER: 1,
})

export const QaSubmissionStatuses = Object.freeze({
  STATUS_PENDING: 0,
  STATUS_VERIFYING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3
})

export const ApplicationHistoryTypes = Object.freeze({
  TYPE_CREATE: { text: "Create", value: 1 },
  TYPE_APPROVAL_REQUEST: { text: "Request Approval", value: 2 },
  TYPE_EDIT: { text: "Edit", value: 3 },
  TYPE_DELETE_REQUEST: { text: "Request Deletion", value: 4 },
  TYPE_DELETE: { text: "Delete", value: 5 },
  TYPE_GENERIC_REQUEST: { text: "Generic Request", value: 6 },
  TYPE_DOWNLOAD: { text: "Download", value: 7 },
  TYPE_ACTIVATION: { text: "Activation", value: 8 },
  TYPE_ARCHIVE: { text: "Archive", value: 9 },
  TYPE_GENERIC_PROCESS: { text: "Generic Process", value: 10 },
  TYPE_UPLOAD: { text: "Upload", value: 11 }
})

export const SpecTypes = Object.freeze({
  selectType: Object.freeze({ value: null, text: 'Select Type', unit: null }),
  time: Object.freeze({
    value: 'time', text: 'Time', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 's', text: 'second (s)', unit: 's' }),
      Object.freeze({ value: 'm', text: 'minute (m)', unit: 'm' }),
      Object.freeze({ value: 'hr', text: 'hour (hr)', unit: 'hr' })
    ])
  }),
  weight: Object.freeze({
    value: 'weight', text: 'Weight', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'g', text: 'gram (g)', unit: 'g' }),
      Object.freeze({ value: 'kg', text: 'kilogram (kg)', unit: 'kg' }),
      Object.freeze({ value: 'mg', text: 'milligram (mg)', unit: 'mg' })
    ])
  }),
  height: Object.freeze({
    value: 'height', text: 'Height', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'cm', text: 'centimeter (cm)', unit: 'cm' }),
      Object.freeze({ value: 'm', text: 'meter (m)', unit: 'm' }),
      Object.freeze({ value: 'mm', text: 'millimeter (mm)', unit: 'mm' })
    ])
  }),
  choice: Object.freeze({
    value: 'choice', text: 'Choice', units: Object.freeze([
      Object.freeze({ value: 'yes', text: 'Yes/No', unit: 'yes/no' }),
    ])
  }),
  speed: Object.freeze({
    value: 'speed', text: 'Speed', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'mps', text: 'meters per second (m/s)', unit: 'm/s' }),
      Object.freeze({ value: 'kph', text: 'kilometers per hour (km/h)', unit: 'km/h' })
    ])
  }),
  distance: Object.freeze({
    value: 'distance', text: 'Length/Distance', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'mm', text: 'millimeter (mm)', unit: 'mm' }),
      Object.freeze({ value: 'cm', text: 'centimeter (cm)', unit: 'cm' }),
      Object.freeze({ value: 'm', text: 'meter (m)', unit: 'm' }),
      Object.freeze({ value: 'km', text: 'kilometer (km)', unit: 'km' })
    ])
  }),
  volume: Object.freeze({
    value: 'volume', text: 'Volume', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'ml', text: 'milliliter (ml)', unit: 'ml' }),
      Object.freeze({ value: 'l', text: 'liter (L)', unit: 'L' })
    ])
  }),
  area: Object.freeze({
    value: 'area', text: 'Area', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'sqm', text: 'square meter (m²)', unit: 'm²' }),
      Object.freeze({ value: 'sqcm', text: 'square centimeter (cm²)', unit: 'cm²' }),
      Object.freeze({ value: 'sqmm', text: 'square millimeter (mm²)', unit: 'mm²' }),
      Object.freeze({ value: 'hectare', text: 'hectare', unit: 'hectare' })
    ])
  }),
  digital: Object.freeze({
    value: 'digital', text: 'Digital', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'b', text: 'byte (B)', unit: 'B' }),
      Object.freeze({ value: 'kb', text: 'kilobyte (KB)', unit: 'KB' }),
      Object.freeze({ value: 'mb', text: 'megabyte (MB)', unit: 'MB' }),
      Object.freeze({ value: 'gb', text: 'gigabyte (GB)', unit: 'GB' }),
      Object.freeze({ value: 'tb', text: 'terabyte (TB)', unit: 'TB' })
    ])
  })
});

export const SocValTypes = Object.freeze({
  selectType: Object.freeze({ value: null, text: 'Select Type', unit: null }),
  time: Object.freeze({
    value: 'time', text: 'Time', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'hrs', text: 'hours', unit: 'hours' }),
      Object.freeze({ value: 'weeks', text: 'weeks', unit: 'weeks' }),
    ])
  }),

  choice: Object.freeze({
    value: 'choice', text: 'Choice', units: Object.freeze([
      Object.freeze({ value: 'yes', text: 'Yes/No', unit: 'yes/no' }),
    ])
  }),

  number: Object.freeze({
    value: 'number', text: 'Number', units: Object.freeze([
      Object.freeze({ value: 'FTE', text: 'FTE', unit: 'FTE' }),
      Object.freeze({ value: 'Individuals', text: 'Individuals', unit: 'Individuals' }),
      Object.freeze({ value: 'Instances', text: 'Instances', unit: 'Instances' }),
      Object.freeze({ value: 'Audits', text: 'Audits', unit: 'Audits' }),
    ])
  }),

  percentage: Object.freeze({
    value: 'percentage', text: 'Percentage', units: Object.freeze([
      Object.freeze({ value: 'Percentage', text: '%', unit: '%' }),
    ])
  }),

  currency: Object.freeze({
    value: 'currency', text: 'Currency', units: Object.freeze([
      Object.freeze({ value: 'GBP', text: 'GBP', unit: 'GBP' }),
    ])
  }),

});

export const ProjectFilters = Object.freeze({
  FILTER_ALL: "all",
  FILTER_COMPANY: "company",
  FILTER_MY: "my"
})

export const ContractYears = Object.freeze([
  Object.freeze({ value: 0, text: '1-4 years' }),
  Object.freeze({ value: 1, text: '4-6 years' }),
  Object.freeze({ value: 2, text: '6-8 years' }),
  Object.freeze({ value: 3, text: '8-10 years' }),
  Object.freeze({ value: 4, text: '10+ years' }),
])

export const MonthyReportCategoryIDs = Object.freeze({
  bff: 1,
  fspf: 2
})