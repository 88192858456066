<template>
  <!-- ADD TO THE SIDENAV UNDER DASHBOARD IF ADDING A NEW ITEM -->
  <aside id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4 bg-white fixed-start ms-3">
    <div class="sidenav-header">
      <i id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"></i>
      <span class="m-0 navbar-brand">
        <img :src="require('@/assets/CPRAS_logo.png')" class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-2 font-weight-bold">CPRAS </span><br><span style="margin-left: 35px;font-size: 12px;"
          class="font-weight-bold">Procurement Platform</span>
      </span>
    </div>

    <hr class="mt-0 horizontal dark" />

    <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
      <ul class="navbar-nav">
        <sidenav-item v-if="isViewable('dashboard')" text="Dashboard" to="/main/dashboard">
          <template #icon>
            <i class="ni ni-spaceship text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>

        <sidenav-collapse text="Meetings/Events" :active="false">
          <template #icon>
            <i class="ni ni-chat-round text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item mini-icon="T" text="My Meetings" to="/main/meetings" />
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse v-if="isViewable('cost_data')" text="Merchant Services Reports"
          :active="isCardTransactionsActive">
          <template #icon>
            <i class="fa-solid fa-landmark text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ">
              <sidenav-collapse text="Merchant Services" :active="isCardTransactionsActive"
                v-if="isViewable('cost_data')">
                <template #icon>
                  <i class="ni ni-credit-card text-info text-sm opacity-10"></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <sidenav-collapse-item mini-icon="C" text="Cost Data and Reports" to="/main/card_transactions/bff"
                      v-if="isViewable('cost_data')" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/invoices/bff"
                      v-if="isViewable('invoice')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Types" to="/main/card_types/bff"
                      v-if="isViewable('card_types')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Type Terms" to="/main/card_type_terms"
                      v-if="isViewable('card_type_terms')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Imports" to="/main/card-imports/bff"
                      v-if="isViewable('card_imports')" />
                  </ul>
                </template>
              </sidenav-collapse>

              <sidenav-collapse text="Banking" :active="isBankReportsActive" v-allowed="'bank_reports,view'">
                <template #icon>
                  <i class="fas fa-landmark text-info text-sm opacity-10"></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <sidenav-collapse-item mini-icon="T" text="Transactions" to="/main/bank_reports/transactions/bff"
                      v-allowed="'bank_reports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/bank_reports/invoices/bff"
                      v-allowed="'invoice,view'" />
                    <sidenav-collapse-item mini-icon="T" text="Tariff Rates" to="/main/bank_reports/tariff_rates/bff"
                      v-allowed="'bank_report_tariffs,view'" />
                    <sidenav-collapse-item mini-icon="F" text="Freebankings" to="/main/bank_reports/freebankings"
                      v-allowed="'bank_report_free_banking,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Imports" to="/main/bank_reports/imports/bff"
                      v-allowed="'bank_report_imports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Exports" to="/main/bank_reports/transactions/bff/exports"
                      v-allowed="'bank_reports,view'" />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse v-if="isViewable('financial_services_and_payments')" text="Financial Svcs. & Payments">
          <template #icon>
            <i class="fa fa-solid fa-money text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ">
              <sidenav-collapse text="Merchant Services" :active="isCardTransactionsActive"
                v-if="isViewable('cost_data')">
                <template #icon>
                  <i class="ni ni-credit-card text-info text-sm opacity-10"></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <sidenav-collapse-item mini-icon="C" text="Cost Data and Reports" to="/main/card_transactions/fspf"
                      v-if="isViewable('cost_data')" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/invoices/fspf"
                      v-if="isViewable('invoice')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Types" to="/main/card_types/fspf"
                      v-if="isViewable('card_types')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Type Terms" to="/main/card_type_terms/fspf"
                      v-if="isViewable('card_type_terms')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Imports" to="/main/card-imports/fspf"
                      v-if="isViewable('card_imports')" />
                  </ul>
                </template>
              </sidenav-collapse>

              <sidenav-collapse text="Banking" :active="isBankReportsActive" v-allowed="'bank_reports,view'">
                <template #icon>
                  <i class="fas fa-landmark text-info text-sm opacity-10"></i>
                </template>
                <template #list>
                  <ul class="nav ms-4">
                    <sidenav-collapse-item mini-icon="T" text="Transactions" to="/main/bank_reports/transactions/fspf"
                      v-allowed="'bank_reports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/bank_reports/invoices/fspf"
                      v-allowed="'invoice,view'" />
                    <sidenav-collapse-item mini-icon="T" text="Tariff Rates" to="/main/bank_reports/tariff_rates/fspf"
                      v-allowed="'bank_report_tariffs,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Imports" to="/main/bank_reports/imports/fspf"
                      v-allowed="'bank_report_imports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Exports"
                      to="/main/bank_reports/transactions/fspf/exports" v-allowed="'bank_reports,view'" />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="Procurement" :active="false" v-if="isViewable('products')">
          <template #icon>
            <i class="fa-solid fa-cart-plus text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item mini-icon="P" text="My Further Competition" v-if="isViewable('supplier')"
                to="/main/product_search_results"></sidenav-collapse-item>

              <sidenav-collapse-item mini-icon="P" text="Projects" v-if="isViewable('procurement_project')"
                to="/main/procurement_projects"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="PIN" v-if="isViewable('pin_notice')"
                to="/main/pin_notices"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="ITT" v-if="isViewable('itt_frameworks')"
                to="/main/itt-frameworks"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="My procurement progress" v-if="isViewable('buyer')"
                to="/main/product_search_queries/list"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="My Saved Searches" v-if="isViewable('buyer')"
                to="/main/saved_searches"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="C" text="My Call-off Documents" v-if="isViewable('buyer')"
                to="/main/buyer-call-off-documents"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="Manage Products" v-allowed="'products,view'"
                to="/main/products"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="Product Categories" v-if="isViewable('settings')"
                to="/main/categories"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="S" text="Specification Setting" v-if="isViewable('settings')"
                to="/main/supplier_specifications"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="S" text="Social Value Setting" v-if="isViewable('settings')"
                to="/main/supplier_social_values"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="F" text="My Call-Off Documents" v-if="isViewable('supplier')"
                to="/main/call-offs"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="M" text="Correspondence" v-if="isViewable('buyer')"
                to="/main/product_search_messages"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="BCP" :active="false" v-if="isViewable('bcp')" @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-gauge text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Overview" icon="fas fa-eye"
                to="/main/bcp/overview"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="Products" icon="fas fa-list"
                to="/main/bcp/products" v-if="isViewable('bcp_products')"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="Suppliers" icon="fas fa-list"
                to="/main/bcp/suppliers" v-if="isViewable('bcp_suppliers')"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="PIN & ITT" :active="false" v-if="isViewable('supplier_hub_all')">
          <template #icon>
            <i class="fa-solid fa-gauge text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="PIN" v-if="isViewable('pin_notice')"
                to="/main/pin_notices"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="ITT" v-if="isViewable('itt_frameworks')"
                to="/main/itt-frameworks"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="Manage Files" v-allowed="'supplier_hub_all, update'"
                to="/supplier-hub/manage"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="P" text="Manage Q&A" v-if="isViewable('answers')"
                to="/questions-and-answers/manage"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="N" text="Notifiy Suppliers" v-if="isViewable('notifications')"
                to="/supplier-hub/notifications"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>



        <sidenav-collapse text="Merchant Acquiring Tool" :active="false" v-if="isViewable('comparison_tool')">
          <template #icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item mini-icon="S" text="Supplier Prospects and Rates" v-if="isViewable('supplier')"
                to="/main/supplier"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="C" text="Cost Comparison" v-if="isViewable('supplier')"
                to="/merchant-services"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="R" text="Reports" v-if="isViewable('comparison_tool')"
                to="/main/merchant-transactions-report"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="M" text="Merchant Terminologies" v-if="isViewable('settings')"
                to="/main/merchant_terminologies"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse text="Banking Tool" :active="isBankingToolActive" v-if="isViewable('comparison_tool')">
          <template #icon>
            <i class="fas fa-landmark text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item mini-icon="S" text="Suppliers & Prospects" to="/main/banking/supplier_prospects" />
              <sidenav-collapse-item mini-icon="T" text="Cost Comparison" to="/main/banking/cost_comparison" />
              <sidenav-collapse-item mini-icon="R" text="Reports" to="/main/banking/reports" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse text="Account" :active="false">
          <template #icon>
            <i class="fa-regular fa-user text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item mini-icon="S" text="Settings" to="/main/account-setting"></sidenav-collapse-item>
              <sidenav-collapse-item mini-icon="M" text="Manage Account"
                to="/main/user_account"></sidenav-collapse-item>

              <sidenav-collapse-item mini-icon="S" text="Company Details" to="/main/supplier"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse text="Finance Module" :active="isFinanceModuleActive" v-if="
            isViewable('finance_receivable') ||
              isViewable('finance_payable') ||
              isViewable('finance')
          ">
          <template #icon>
            <i class="fa-solid fa-coins text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item v-if="isViewable('finance_receivable') || isViewable('finance_payable')"
                mini-icon="C" text="Calendar" to="/main/finance_module/calendar"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item v-if="isViewable('finance')" mini-icon="AG" text="Aging Report"
                to="/finance/aging-report"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item v-if="isViewable('finance_codes')" mini-icon="CA" text="Chart of Accounts"
                to="/finance/settings/chart-of-accounts"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item v-if="isViewable('finance_codes')" mini-icon="CA" text="Types"
                to="/finance/settings/types"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item v-if="isViewable('finance_codes')" mini-icon="CA" text="Areas"
                to="/finance/settings/areas"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-item v-if="isViewable('file_storage')" text="Files" to="/main/files">
          <template #icon>
            <i class="ni ni-archive-2 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>

        <sidenav-collapse v-if="isViewable('settings')" text="Settings" :active="isSettingsActive">
          <template #icon>
            <i class="ni ni-settings-gear-65 text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item v-if="isViewable('application_histories') " mini-icon="S" text="Application Histories"
                to="/main/application_histories" />
              <sidenav-collapse-item v-if="isViewable('project_participants') " mini-icon="S" text="Project Participants"
                to="/main/project_list_settings" />
              <sidenav-collapse-item v-if="isViewable('supplier_qa_submission') " mini-icon="S" text="Supplier QA Submissions"
                to="/main/evaluation_result_submissions" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('help_resources')" mini-icon="S"
                text="Manage Video Resources" to="/main/manage-help-resources" />
              <sidenav-collapse-item v-if="isViewable('bcp_approvals') " mini-icon="S" text="BCP Approval"
                to="/main/settings/bcp-approvals" />
              <sidenav-collapse-item v-if="isViewable('call_off_approvals') " mini-icon="S" text="Call Off Approval"
                to="/main/settings/call-off-approvals" />
              <sidenav-collapse-item v-if="isViewable('review_products')" mini-icon="S" text="Review Products"
                to="/main/settings/review-products" />
              <sidenav-collapse-item v-if="isViewable('firebase_notification')" mini-icon="S"
                text="Firebase Notifications" to="/main/firebase_notifications" />
              <sidenav-collapse-item v-if="isViewable('all_card_imports')" mini-icon="S" text="All Card Imports"
                to="/main/all-card-imports" />
              <sidenav-collapse-item v-if="isViewable('all_bank_report_imports')" mini-icon="S" text="All Bank Imports"
                to="/main/all_bank_report_imports" />
              <sidenav-collapse-item v-if="isViewable('audit')" mini-icon="S" text="Server Logs" to="/main/audits" />
              <sidenav-collapse-item v-if="isViewable('settings')" mini-icon="S" text="Global Settings"
                to="/main/settings" />
              <sidenav-collapse-item v-if="isViewable('users')" mini-icon="U" text="Users" to="/main/users" />
              <sidenav-collapse-item v-if="isViewable('roles')" mini-icon="R" text="Roles" to="/main/roles" />
              <sidenav-collapse-item v-if="isViewable('permissions')" mini-icon="P" text="Permissions"
                to="/main/permissions" />
              <sidenav-collapse-item v-if="isViewable('companies')" mini-icon="C" text="Companies"
                to="/main/companies" />
              <sidenav-collapse-item mini-icon="G" text="Global Specifications" to="/main/specifications" />
              <sidenav-collapse-item mini-icon="G" text="Global Social Values" to="/main/social_values" />
              <sidenav-collapse-item mini-icon="A" text="Authorization Fees" to="/main/authorization_fees" />
              <sidenav-collapse-item v-if="isViewable('councils')" mini-icon="U" text="Councils" to="/main/councils" />
              <sidenav-collapse-item v-if="isViewable('company_facas')" mini-icon="F" text="Company Faca List"
                to="/main/company-faca-list" />
              <sidenav-collapse-item v-if="isViewable('framework_names')" mini-icon="F" text="Framework Names"
                to="/main/framework_names" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-item v-if="isViewable('dashboard')" text="Contact Support Team" to="/main/support-ticket">
          <template #icon>
            <i class="ni ni-headphones text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </ul>
    </div>

    <div class="mt-5 sidenav-footer">
      <sidenav-card :card="{
          links: [
            {
              label: 'Logout',
              route: '/logout',
              color: 'outline-danger'
            }
          ]
        }" />
    </div>
  </aside>
  <button @click="toggleSidebar" class="btn d-block d-xl-none btn-appearance">
    <i class="fas fa-bars" id="hamburger"></i>
  </button>
  <div class="d-flex flex-column flex-shrink-0 bg-light navbar-vertical navbar-expand-xs custom-navbar"
    id="custom-sidebar" style="width: 3.75rem;">
    <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100" style="margin-top: 5rem">
      <ul class="navbar-nav">
        <sidenav-item v-if="isViewable('dashboard')" text="Dashboard" to="/main/dashboard" @click="closeSidebar">
          <template #icon>
            <i class="ni ni-spaceship text-primary text-sm opacity-10 icon-sidebar"></i>
          </template>
        </sidenav-item>



        <sidenav-collapse v-if="isViewable('banking_and_finance')" text="Banking and Finance">
          <template #icon>
            <i class="fa-solid fa-landmark text-warning text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ">
              <sidenav-collapse text="Merchant Services" :active="isCardTransactionsActive"
                v-if="isViewable('cost_data')">
                <template #icon>
                  <i class="ni ni-credit-card text-info text-sm opacity-10 icon-sidebar"></i>
                </template>
                <template #list>
                  <ul class="nav ms-5">
                    <sidenav-collapse-item mini-icon="C" text="Cost Data and Reports" to="/main/card_transactions/bff"
                      v-if="isViewable('cost_data')" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/invoices/bff"
                      v-if="isViewable('invoice')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Types" to="/main/card_types/bff"
                      v-if="isViewable('card_types')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Type Terms" to="/main/card_type_terms"
                      v-if="isViewable('card_type_terms')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Imports" to="/main/card-imports/bff"
                      v-if="isViewable('card_imports')" />
                  </ul>
                </template>
              </sidenav-collapse>

              <sidenav-collapse text="Banking" :active="isBankReportsActive" v-allowed="'bank_reports,view'">
                <template #icon>
                  <i class="fas fa-landmark text-info text-sm opacity-10 icon-sidebar"></i>
                </template>
                <template #list>
                  <ul class="nav ms-5">
                    <sidenav-collapse-item mini-icon="T" text="Transactions" to="/main/bank_reports/transactions/bff"
                      v-allowed="'bank_reports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/bank_reports/invoices/bff"
                      v-allowed="'invoice,view'" />
                    <sidenav-collapse-item mini-icon="T" text="Tariff Rates" to="/main/bank_reports/tariff_rates/bff"
                      v-allowed="'bank_report_tariffs,view'" />
                    <sidenav-collapse-item mini-icon="F" text="Freebankings" to="/main/bank_reports/freebankings"
                      v-allowed="'bank_report_free_banking,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Imports" to="/main/bank_reports/imports/bff"
                      v-allowed="'bank_report_imports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Exports" to="/main/bank_reports/transactions/bff/exports"
                      v-allowed="'bank_reports,view'" />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse v-if="isViewable('financial_services_and_payments')" text="Financial Svcs. & Payments">
          <template #icon>
            <i class="fa fa-solid fa-money text-warning text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ">
              <sidenav-collapse text="Merchant Services" :active="isCardTransactionsActive"
                v-if="isViewable('cost_data')">
                <template #icon>
                  <i class="ni ni-credit-card text-info text-sm opacity-10 icon-sidebar"></i>
                </template>
                <template #list>
                  <ul class="nav ms-5">
                    <sidenav-collapse-item mini-icon="C" text="Cost Data and Reports" to="/main/card_transactions/fspf"
                      v-if="isViewable('cost_data')" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/invoices/fspf"
                      v-if="isViewable('invoice')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Types" to="/main/card_types/fspf"
                      v-if="isViewable('card_types')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Type Terms" to="/main/card_type_terms"
                      v-if="isViewable('card_type_terms')" />
                    <sidenav-collapse-item mini-icon="C" text="Card Imports" to="/main/card-imports/fspf"
                      v-if="isViewable('card_imports')" />
                  </ul>
                </template>
              </sidenav-collapse>

              <sidenav-collapse text="Banking" :active="isBankReportsActive" v-allowed="'bank_reports,view'">
                <template #icon>
                  <i class="fas fa-landmark text-info text-sm opacity-10 icon-sidebar"></i>
                </template>
                <template #list>
                  <ul class="nav ms-5">
                    <sidenav-collapse-item mini-icon="T" text="Transactions" to="/main/bank_reports/transactions/fspf"
                      v-allowed="'bank_reports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Invoices" to="/main/bank_reports/invoices/fspf"
                      v-allowed="'invoice,view'" />
                    <sidenav-collapse-item mini-icon="T" text="Tariff Rates" to="/main/bank_reports/tariff_rates/fspf"
                      v-allowed="'bank_report_tariffs,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Imports" to="/main/bank_reports/imports/fspf"
                      v-allowed="'bank_report_imports,view'" />
                    <sidenav-collapse-item mini-icon="I" text="Exports"
                      to="/main/bank_reports/transactions/fspf/exports" v-allowed="'bank_reports,view'" />
                  </ul>
                </template>
              </sidenav-collapse>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="BCP" :active="false" v-if="isViewable('bcp')" @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-gauge text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Overview" icon="fas fa-eye"
                to="/main/bcp/overview"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="Products" icon="fas fa-list"
                to="/main/bcp/products" v-if="bcpEnabled('products')"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="Suppliers" icon="fas fa-list"
                to="/main/bcp/suppliers" v-if="bcpEnabled('suppliers')"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="Procurement" :active="false" v-if="isViewable('products')" @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-cart-plus text-success text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="My Further Competition"
                v-if="isViewable('supplier')" to="/main/product_search_results"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="My procurement progress"
                v-if="isViewable('buyer')" to="/main/product_search_queries/list"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="My Saved Searches"
                v-if="isViewable('buyer')" to="/main/saved_searches"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="My Call-off Documents"
                v-if="isViewable('buyer')" to="/main/buyer-call-off-documents"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Manage Products"
                v-allowed="'products, update'" to="/main/products"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Product Categories"
                v-if="isViewable('settings')" to="/main/categories"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Specification Setting"
                v-if="isViewable('settings')" to="/main/supplier_specifications"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Social Value Setting"
                v-if="isViewable('settings')" to="/main/supplier_social_values"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="F" text="My Call-Off Documents"
                v-if="isViewable('supplier')" to="/main/call-offs"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="M" text="Correspondence"
                v-if="isViewable('buyer')" to="/main/product_search_messages"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Project"
                v-if="isViewable('procurement_project')" to="/main/procurement_projects"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="PIN" v-if="isViewable('pin_notice')"
                to="/main/pin_notices"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="ITT" v-if="isViewable('itt_frameworks')"
                to="/main/itt-frameworks"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="PIN & ITT" :active="false" v-if="isViewable('supplier_hub_all')" @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-gauge text-success text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Dashboard"
                v-allowed="'supplier_hub_all, view'" to="/supplier-hub/dashboard"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Manage Files"
                v-allowed="'supplier_hub_all, update'" to="/supplier-hub/manage"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Dashboard"
                v-allowed="'supplier_hub_all, view'" to="/supplier-hub/dashboard"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Manage Files"
                v-allowed="'supplier_hub_all, update'" to="/supplier-hub/manage"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="PIN" v-if="isViewable('pin_notice')"
                to="/main/pin_notices"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="ITT" v-if="isViewable('itt_frameworks')"
                to="/main/itt-frameworks"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Manage Files"
                v-allowed="'supplier_hub_all, update'" to="/supplier-hub/manage"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="P" text="Manage Q&A" v-if="isViewable('answers')"
                to="/questions-and-answers/manage"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="N" text="Notifiy Suppliers"
                v-if="isViewable('notifications')" to="/supplier-hub/notifications"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse text="Merchant Acquiring Tool" :active="false" v-if="isViewable('comparison_tool')"
          @click="showSidebar">
          <template #icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Supplier Prospects and Rates"
                v-if="isViewable('supplier')" to="/main/supplier"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="C" text="Cost Comparison"
                v-if="isViewable('supplier')" to="/merchant-services"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="R" text="Reports"
                v-if="isViewable('comparison_tool')" to="/main/merchant-transactions-report"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="M" text="Merchant Terminologies"
                v-if="isViewable('settings')" to="/main/merchant_terminologies"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse text="Banking Tool" :active="isBankingToolActive" v-if="isViewable('comparison_tool')"
          @click="showSidebar">
          <template #icon>
            <i class="fas fa-landmark text-success text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Suppliers & Prospects"
                to="/main/banking/supplier_prospects" />
              <sidenav-collapse-item @click="closeSidebar" mini-icon="T" text="Cost Comparison"
                to="/main/banking/cost_comparison" />
              <sidenav-collapse-item @click="closeSidebar" mini-icon="R" text="Reports" to="/main/banking/reports" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse text="Account" :active="false" @click="showSidebar">
          <template #icon>
            <i class="fa-regular fa-user text-success text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">

              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Settings"
                to="/main/account-setting"></sidenav-collapse-item>
              <sidenav-collapse-item @click="closeSidebar" mini-icon="M" text="Manage Account"
                to="/main/user_account"></sidenav-collapse-item>

              <sidenav-collapse-item @click="closeSidebar" mini-icon="S" text="Company Details"
                to="/main/supplier"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse text="Finance Module" :active="isFinanceModuleActive" v-if="
            isViewable('finance_receivable') ||
              isViewable('finance_payable') ||
              isViewable('finance')
          " @click="showSidebar">
          <template #icon>
            <i class="fa-solid fa-coins text-success text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar"
                v-if="isViewable('finance_receivable') || isViewable('finance_payable')" mini-icon="C" text="Calendar"
                to="/main/finance_module/calendar"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('finance')" mini-icon="AG"
                text="Aging Report" to="/finance/aging-report"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('finance_codes')" mini-icon="CA"
                text="Chart of Accounts" to="/finance/settings/chart-of-accounts"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('finance_codes')" mini-icon="CA"
                text="Types" to="/finance/settings/types"></sidenav-collapse-item>
            </ul>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('finance_codes')" mini-icon="CA"
                text="Areas" to="/finance/settings/areas"></sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-item v-if="isViewable('file_storage')" text="Files" to="/main/files">
          <template #icon>
            <i class="ni ni-archive-2 text-success text-sm opacity-10 icon-sidebar"></i>
          </template>
        </sidenav-item>


        <sidenav-collapse v-if="isViewable('settings')" text="Settings" :active="isSettingsActive" @click="showSidebar">
          <template #icon>
            <i class="ni ni-settings-gear-65 text-warning text-sm opacity-10 icon-sidebar"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('application_histories')" mini-icon="B"
                text="Application Histories" to="/main/application_histories" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('project_participants')" mini-icon="B"
                text="Project Participants" to="/main/project_list_settings" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('supplier_qa_submission')" mini-icon="B"
                text="Supplier QA Submissions" to="/main/evaluation_result_submissions" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('bcp_approvals')" mini-icon="B"
                text="BCP Approval" to="/main/settings/bcp-approvals" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('call_off_approvals') " mini-icon="S"
                text="Call Off" to="/main/settings/call-off-approvals" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('review_products')" mini-icon="S"
                text="Review Products" to="/main/settings/review-products" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('help_resources') " mini-icon="S"
                text="Manage Video Resources" to="/main/manage-help-resources" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('call_off_approvals') " mini-icon="S"
                text="Call Off " to="/main/settings/call-off-approvals" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('review_products')" mini-icon="S"
                text="Review Products" to="/main/settings/review-products" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('firebase_notification')" mini-icon="S"
                text="Firebase Notifications" to="/main/firebase_notifications" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('all_card_imports')" mini-icon="S"
                text="All Card Imports" to="/main/all-card-imports" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('all_bank_report_imports')" mini-icon="S"
                text="All Bank Imports" to="/main/all_bank_report_imports" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('audit')" mini-icon="S" text="Server Logs"
                to="/main/audits" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('settings')" mini-icon="S"
                text="Global Settings" to="/main/settings" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('users')" mini-icon="U" text="Users"
                to="/main/users" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('roles')" mini-icon="R" text="Roles"
                to="/main/roles" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('permissions')" mini-icon="P"
                text="Permissions" to="/main/permissions" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('companies')" mini-icon="C" text="Companies"
                to="/main/companies" />
              <sidenav-collapse-item @click="closeSidebar" mini-icon="G" text="Global Specifications"
                to="/main/specifications" />
              <sidenav-collapse-item @click="closeSidebar" mini-icon="G" text="Global Social Values"
                to="/main/social_values" />
              <sidenav-collapse-item @click="closeSidebar" mini-icon="A" text="Authorization Fees"
                to="/main/authorization_fees" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('councils')" mini-icon="U" text="Councils"
                to="/main/councils" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('company_facas')" mini-icon="F"
                text="Company Faca List" to="/main/company-faca-list" />
              <sidenav-collapse-item @click="closeSidebar" v-if="isViewable('framework_names')" mini-icon="F"
                text="Framework Names" to="/main/framework_names" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-item v-if="isViewable('dashboard')" text="Contact Support Team" to="/main/support-ticket">
          <template #icon>
            <i class="ni ni-headphones text-primary text-sm opacity-10 icon-sidebar"></i>
          </template>
        </sidenav-item>
      </ul>
    </div>
  </div>
</template>

<script>
import SidenavItem from './SidenavItem.vue'
import SidenavCollapse from './SidenavCollapse.vue'
import SidenavCollapseItem from './SidenavCollapseItem.vue'
import SidenavCard from './SidenavCard.vue'

export default {
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem,
    SidenavCard
  },
  data() {
    return {
      route_name: null,
      is_website_bcp: parseInt(process.env.VUE_APP_IS_BCP),
      user: this.$store.state.savedUser 
    }
  },
  watch: {
    $route(to, from) {
      this.route_name = to.name
    }
  },
  created: function() {
    this.route_name = this.$route.name
    this.user = this.$store.state.savedUser 
  },
  computed: {
    isCardTransactionsActive() {
      return [
        'card_transactions',
        'card_types',
        'card_type_terms',
        'card_imports',
        'invoices'
      ].includes(this.route_name)
    },
    isBankReportsActive() {
      return [
        'br_transaction_list',
        'br_transaction_reports',
        'br_transaction_history',
        'br_tariff_rates',
        'br_freebankings',
        'br_imports',
        'br_invoices',
        'br_exports'
      ].includes(this.route_name)
    },
    isSettingsActive() {
      return [
        'users',
        'roles',
        'permissions',
        'specifications',
        'authorization_fees'
      ].includes(this.route_name)
    },
    isBankingToolActive() {
      return [
        'banking_supplier_prospects',
        'banking_tariff_rates',
        'banking_cost_comparison',
        'banking_cost_comparison_edit',
        'banking_reports'
      ].includes(this.route_name)
    },
    isFinanceModuleActive() {
      return ['finance_module_calendar'].includes(this.route_name)
    }
  },
  methods: {
    toggleSidebar() {
      const sidenavMain = document.getElementById('custom-sidebar');
      const hamburgerBtn = document.getElementById('hamburger');

      if (sidenavMain.classList.contains('show-sidebar')) {
        sidenavMain.classList.remove('show-sidebar');
        hamburgerBtn && hamburgerBtn.classList.remove('fa-close');
        hamburgerBtn && hamburgerBtn.classList.add('fa-bars');
      } else {
        sidenavMain.classList.add('show-sidebar');
        hamburgerBtn && hamburgerBtn.classList.add('fa-close');
        hamburgerBtn && hamburgerBtn.classList.remove('fa-bars');
      }
    },
    showSidebar() {
      const sidenavMain = document.getElementById('custom-sidebar');
      const hamburgerBtn = document.getElementById('hamburger');
      
      sidenavMain.classList.add('show-sidebar');
      hamburgerBtn && hamburgerBtn.classList.add('fa-close');
    },
    closeSidebar() {
        const sidenavMain = document.getElementById('custom-sidebar');
        const hamburgerBtn = document.getElementById('hamburger');
        
        setTimeout(function() {
          sidenavMain.classList.remove('show-sidebar');
          hamburgerBtn && hamburgerBtn.classList.remove('fa-close');
          hamburgerBtn && hamburgerBtn.classList.add('fa-bars');
      }, 1)
        
    },
    isActive(name) {
      return this.route_name === name
    },
    isViewable(name) {
      var response = false

      if (name == 'bcp_products') {
        return this.user?.bcp_status == 1 && this.isSupplier() 
      }

      if (name == 'bcp_suppliers') {
        return this.user?.bcp_status == 1 && this.isBuyer()
      }

      // if (this.user?.bcp_status == 1 && this.isBuyer()) {
      //   let listAllowed = ['DASHBOARD','BCP','BUYER', 'PRODUCTS']
      //   if (!listAllowed.includes(name.toUpperCase())) return false
      // }

      if (this.user) {
        if (this.user.permissions) {
          this.user.permissions.forEach(function(permission) {
            if (
              permission.action === 'VIEW' &&
              permission.module.toLowerCase() === name
            ) {
              response = true
            }
          })
        }
      }
      return response
    },
    isBuyer(){
      let isBuyer = false
      let role_names = this.user?.role_names || []
      role_names.forEach(function (role) {
        if (role.toUpperCase() == 'BUYER') isBuyer = true
      })

      return isBuyer
    },
    isSupplier() {
      let isSupplier = false
      let role_names = this.user?.role_names || []
      role_names.forEach(function (role) {
        if (role.toUpperCase() == 'SUPPLIER') isSupplier = true
      })

      return isSupplier
    },
    bcpEnabled(module) {
      if (module == 'products' && this.isBuyer() && !this.isSupplier()) return false
      return this.user?.bcp_status == 1
    }
  }
}
</script>

<style scoped>
.navbar-vertical.navbar-expand-xs {
  z-index: 1039;
}
.icon-sm i.fa,
.icon-sm i.fa-solid,
.icon-sm i.fas {
  top: 0;
}
.navbar-vertical.navbar-expand-xs .navbar-nav ::v-deep .nav-link {
  font-size: 0.835rem;
}
.btn-appearance {
  position: fixed;
  z-index: 9999;
  left: 0.25rem;
}
.icon-sidebar {
  padding-right: 1.1rem !important;
}

@media (min-width: 1200px) {
  .btn-appearance {
    display: hidden;
  }
  .custom-navbar {
    left: -18.5rem !important;
  }
}
.show-sidebar {
  width: 18.5rem !important;
  transition: 0.2s;
}
</style>
